import { StepDefinition } from '../../types';

export default {
  key: 'search',
  name: 'Search Exa',
  description: 'Will search the exa search engine.',
  icon: 'i-respell-exa',
  //documentationLink: 'https://docs.respell.ai/exa',
  category: 'integrations',
  services: ['exa'],
  options: {
    query: {
      type: 'text/plain',
      isOptional: false,
      description: 'The query string. Required.',
      name: 'Query',
      listDepth: 0,
      value: null,
      key: 'query',
    },
  },
  outputs: {
    result: {
      type: 'object',
      isOptional: false,
      description: 'The results of the search.',
      name: 'Result',
      listDepth: 1,
      metadata: {
        schema: {
          title: {
            type: 'text/plain',
            key: 'title',
            name: 'Title',
            description: 'The title of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          url: {
            type: 'text/url',
            key: 'url',
            name: 'URL',
            description: 'The URL of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          snippet: {
            type: 'text/plain',
            name: 'Snippet',
            key: 'snippet',
            description: 'A snippet of the search result.',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
      key: 'result',
    },
  },
} satisfies StepDefinition;
