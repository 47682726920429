import { StepDefinition } from '../../types';

export default {
  key: 'create_company',
  name: 'Create Company',
  description: 'Creates a Hubspot Company',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    account: {
      key: 'account',
      name: 'Account',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_company',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
