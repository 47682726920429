<script setup lang="ts">
import { useChat } from '@ai-sdk/vue';

const { initialPrompt } = defineProps({
  initialPrompt: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['accept']);

const modal = useModal();
const canvasStore = useCanvasStore();

const { messages, input, append } = useChat();
const { promptContext, needsContext } = storeToRefs(canvasStore);

const additional = ref('');
const state = ref('editingPrompt');
const promptCount = ref(0);
const sampleCount = ref(0);
const modelChoice = ref('gpt-4-turbo-2024-04-09');

const hasImproved = computed(() => {
  return promptCount.value > 0;
});

const context = computed(() => {
  return JSON.stringify(promptContext.value);
});

const livePrompt = reactiveComputed(() => {
  return {
    content: `${hasImproved.value || state.value === 'improving' ? input.value : initialPrompt ?? ''}${additional.value ? `\n${additional.value}` : ''}`,
  };
});

const trainContent = computed(() => {
  return `${livePrompt.content}${context.value ? `\n# CONTEXT:\n${context.value}` : ''}`;
});

const sample = reactiveComputed(() => {
  return {
    content:
      messages.value.find((message) => message.role === 'assistant')?.content ??
      '',
  };
});

watchDebounced(
  livePrompt,
  () => {
    if (state.value === 'editingPrompt') {
      canvasStore.loadPromptContext(livePrompt.content);
    }
  },
  {
    debounce: 1000,
    deep: true,
    immediate: true,
  },
);

// Submit prompt to execute on the server:
const onPromptSubmit = async (): Promise<void> => {
  // Do not call the server if there is no prompt:
  if (!input.value.length) {
    return;
  }

  messages.value = [];
  state.value = 'generating';

  // "Append" is the "ai/react" method to add a new message to the prompt
  // and call the API for a response:
  await append({
    createdAt: new Date(),
    role: 'user',
    content: trainContent.value,
  });

  state.value = 'idle';
  sampleCount.value += 1;
};

// Call training API to improve prompt based on user correction:
const handleTrain = async () => {
  const trainingResponse = await callTrainingApi({
    generation:
      messages.value.find((message) => message.role === 'assistant')?.content ??
      '',
    correction: sample.content,
  });

  // Store training information for use in the prompt:
  additional.value = trainingResponse.prompt;
  state.value = 'idle';
  promptCount.value += 1;

  // Submit new version of the prompt:
  await onPromptSubmit();
};

const callImprovementApi = async () => {
  state.value = 'improving';
  // Must use regular fetch for streaming
  const response = await fetch('/api/prompt/improve', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prompt: input.value,
      context: context.value,
      model: modelChoice.value,
    }),
  });

  let content = '';
  const reader = response.body?.getReader();
  const decoder = new TextDecoder('utf-8');
  let done = false;
  if (reader) {
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      content += decoder.decode(value, { stream: true });
      input.value = content;
    }
  }

  state.value = 'idle';
  promptCount.value += 1;
};

const callTrainingApi = async ({
  generation,
  correction,
}: {
  generation: string;
  correction: string;
}) => {
  state.value = 'training';
  const response = await $api('/api/prompt/train', {
    method: 'POST',
    body: JSON.stringify({
      prompt: input.value,
      context: context.value,
      generation,
      correction,
      model: modelChoice.value,
    }),
  });

  return response;
};

async function handleGenerate() {
  if (!hasImproved.value) {
    input.value = livePrompt.content;
    await callImprovementApi();
  }
  await onPromptSubmit();
}

function acceptPrompt() {
  emit('accept', livePrompt.content);
  modal.close();
}
</script>
<template>
  <UModal
    :ui="{
      width: 'sm:max-w-6xl',
      rounded: 'rounded-2xl',
    }"
  >
    <UCard
      :ui="{
        base: 'w-full',
        rounded: 'rounded-2xl',
        body: { base: 'flex flex-col gap-4' },
      }"
    >
      <span class="flex justify-between w-full mb-2">
        <p class="main-title">Prompt Wizard</p>
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          @click="modal.close"
        />
      </span>
      <div
        class="grid grid-cols-[minmax(0,1fr),3rem,minmax(0,1fr)] w-full items-start"
      >
        <!-- PROMPT -->
        <UFormGroup
          label="Your prompt"
          description="This will prompt the AI at runtime"
          class="w-full"
          :error="needsContext"
        >
          <USkeleton
            v-if="state === 'training'"
            class="h-96 w-full"
            :ui="{
              background: 'bg-primary-100',
              rounded: 'rounded-lg',
            }"
          />
          <AppTextEditor
            v-else
            :key="promptCount"
            v-model="livePrompt.content"
            injectable
            placeholder="Enter your prompt here"
            is-multiline
            :readonly="
              ['editingSample', 'improving', 'generating'].includes(state)
            "
            :class="{
              'border-pulse': ['editingPrompt', 'improving'].includes(state),
              '!border-red-500': needsContext,
            }"
            is-prompt
            :is-streaming="state === 'improving'"
            @update:model-value="state = 'editingPrompt'"
          />

          <template v-if="needsContext" #error="{ error }">
            <span class="w-full h-14 flex">
              <UAlert
                title="Provide context for all variables before generating a sample"
                color="red"
                variant="soft"
              />
            </span>
          </template>
          <template v-else #help>
            <span class="w-full h-14 flex">
              <UAlert
                v-if="['editingPrompt', 'idle'].includes(state)"
                :title="
                  state === 'idle'
                    ? 'Edit this prompt to generate a new sample'
                    : !hasImproved
                      ? 'Click Generate to refine this prompt and generate a sample output'
                      : 'Click Generate to generate a new sample'
                "
                color="primary"
                variant="soft"
              />
              <UProgress
                v-else-if="['improving', 'training'].includes(state)"
                :animation="
                  state === 'improving' ? 'carousel' : 'carousel-inverse'
                "
                :max="['Improving your prompt...']"
                size="md"
                :ui="{
                  wrapper: 'items-end',
                }"
              />
            </span>
          </template>
        </UFormGroup>

        <span class="flex flex-col h-full">
          <UDivider
            v-if="state === 'idle'"
            label="OR"
            class="mt-auto mb-4"
            :ui="{
              label: 'text-primary-400',
            }"
          />
          <UIcon
            v-else
            :name="
              ['training', 'editingSample'].includes(state)
                ? 'i-ph-arrow-circle-left-fill'
                : 'i-ph-arrow-circle-right-fill'
            "
            class="text-primary-400 text-2xl"
            :class="{
              'animate-pulse': ['generating', 'improving', 'training'].includes(
                state,
              ),
            }"
          />
        </span>
        <!-- SAMPLE -->

        <UFormGroup
          label="Result sample"
          description="This is an example of what the AI might generate at runtime"
          class="w-full"
        >
          <AppTextEditor
            :key="sampleCount"
            v-model="sample.content"
            :is-streaming="state === 'generating'"
            is-multiline
            placeholder="Generate a sample by clicking the 'Generate' button"
            :readonly="
              ['editingPrompt', 'improving', 'generating'].includes(state) ||
              !hasImproved
            "
            :class="{
              'border-pulse': ['editingSample', 'generating'].includes(state),
            }"
            is-sample
            @update:model-value="state = 'editingSample'"
          />
          <template #help>
            <span class="w-full flex h-14">
              <UAlert
                v-if="['editingSample', 'idle'].includes(state)"
                :title="
                  state === 'idle'
                    ? 'Edit this sample to further refine the prompt'
                    : 'Click Generate to further refine the prompt'
                "
                color="primary"
                variant="soft"
              />
              <UProgress
                v-else-if="state === 'generating'"
                animation="carousel"
                size="md"
                :max="['Generating a sample output...']"
                :ui="{
                  wrapper: 'items-end',
                }"
              />
            </span>
          </template>
        </UFormGroup>
      </div>
      <div class="flex w-full justify-end gap-4">
        <UButton
          v-if="state === 'editingPrompt'"
          variant="solid"
          size="xl"
          color="white"
          icon="i-ph-sparkle"
          :disabled="needsContext || !livePrompt.content?.length"
          label="Generate"
          @click="handleGenerate"
        />

        <UButton
          v-else-if="state === 'editingSample'"
          variant="solid"
          size="xl"
          color="white"
          icon="i-ph-sparkle"
          label="Update Prompt"
          @click="handleTrain"
        />

        <UButton
          variant="solid"
          size="xl"
          color="primary"
          :disabled="!hasImproved"
          icon="i-ph-file-text"
          label="Use Prompt"
          @click="acceptPrompt"
        />
      </div>
    </UCard>
  </UModal>
</template>
<style lang="scss">
@keyframes pulsate {
  0% {
    border-color: #9296fa;
  }
  50% {
    border-color: #5344e5;
  }
  100% {
    border-color: #9296fa;
  }
}

.border-pulse {
  border: 2px solid;
  animation: pulsate 2s infinite;
}
</style>
