import { Dictionary } from '@respell/utils';
import airtable from './airtable';
import apollo from './apollo';
import new_account from './apollo/triggers/apollo-accounts';
import new_contact from './apollo/triggers/apollo-contacts';
import ashby from './ashby';
import new_canididate from './ashby/triggers/ashby-candidates';
import new_job from './ashby/triggers/ashby-jobs';
import bland from './bland';
import inbound from './bland/triggers/inbound';
import coda from './coda';
import { TriggerDefinition } from './definition';
import new_envelope from './docusign/triggers/new_envelope';
import dropbox from './dropbox';
import exa from './exa';
import fireflies from './fireflies';
import gcp from './gcp';
import gmail from './gmail';
import emails from './gmail/triggers/emails';
import googleCalendar from './google-calendar';
import events from './google-calendar/triggers/events';
import googledrive from './google-drive';
import googlesheets from './google-sheet';
import new_row from './google-sheet/triggers/new-row/index';
import greenhouse from './greenhouse';
import highlevel from './highlevel';
import new_highlevel_contact from './highlevel/triggers/new_highlevel_contact';
import new_highlevel_opportunity from './highlevel/triggers/new_highlevel_opportunity';
import hubspot from './hubspot';
import new_hubspot_company from './hubspot/triggers/new_hubspot_company';
import new_hubspot_contact from './hubspot/triggers/new_hubspot_contact';
import intercom from './intercom';
import intercom_conversations from './intercom/triggers/intercom-conversations';
import jotform from './jotform';
import new_submission from './jotform/triggers/new_submission';
import linear from './linear';
import new_issue from './linear/triggers/linear-issues';
import mailchimp from './mailchimp';
import mailgun from './mailgun';
import notion from './notion';
import new_page from './notion/triggers/notion-page';
import onedrive from './one-drive';
import outlook from './outlook';
import peopledatalabs from './peopledatalabs';
import salesforce from './salesforce';
import sendgrid from './sendgrid';
import global from './sendgrid/triggers/global';
import slack from './slack';
import messages from './slack/triggers/messages';
import typeform from './typeform';
import new_submission_tf from './typeform/triggers/new_submission_tf';
import zendesk from './zendesk';
import new_ticket_zd from './zendesk/triggers/new_ticket_zd';
import new_email from './outlook/triggers/new_email';
import new_file from './one-drive/triggers/new_file';

const integrations = {
  linear,
  intercom,
  airtable,
  bland,
  coda,
  notion,
  apollo,
  gcp,
  ['google-sheet']: googlesheets,
  ['google-drive']: googledrive,
  slack,
  ['google-calendar']: googleCalendar,
  ['one-drive']: onedrive,
  zendesk,
  exa,
  outlook,
  // pandadoc,
  dropbox,
  fireflies,
  gmail,
  sendgrid,
  salesforce,
  highlevel,
  typeform,
  peopledatalabs,
  hubspot,
  jotform,
  ashby,
  greenhouse,
  mailchimp,
  // zoominfo,
  mailgun,
  // docusign,
};

export const triggers: Dictionary<Dictionary<TriggerDefinition>> = {
  notion: {
    ['notion-page']: new_page,
  },
  jotform: {
    new_submission,
  },
  ['google-sheet']: {
    ['new-row']: new_row,
  },
  apollo: {
    ['apollo-accounts']: new_account,
    ['apollo-contacts']: new_contact,
  },
  outlook: {
    new_email
  },
  ['one-drive']: {
    new_file,
  },
  bland: {
    ['inbound']: inbound,
  },
  intercom: {
    ['intercom-conversations']: intercom_conversations,
  },
  linear: {
    ['linear-issues']: new_issue,
  },
  // mailchimp: {
  //   clicked,
  //   opened,
  //   inbound,
  // },
  // mailgun: {
  //   clickedmg,
  //   openedmg,
  // },
  // pandadoc: {
  //   new_envelope_pd,
  // },
  highlevel: {
    new_highlevel_opportunity,
    new_highlevel_contact,
  },
  gmail: {
    emails,
  },
  ['google-calendar']: {
    events,
  },
  slack: {
    messages,
  },
  hubspot: {
    new_hubspot_contact,
    new_hubspot_company,
  },
  sendgrid: {
    global,
  },
  ashby: {
    ['ashby-candidates']: new_canididate,
    ['ashby-jobs']: new_job,
  },
  typeform: {
    new_submission_tf,
  },
  zendesk: {
    new_ticket_zd,
  },
  docusign: {
    new_envelope: new_envelope,
  },
};

export default integrations;
