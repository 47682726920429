import { useVueFlow } from '@vue-flow/core';

export function useSelectedStep() {
  const { getSelectedNodes } = useVueFlow({ id: 'editor' });
  const selectedStep = computed(() =>
    getSelectedNodes.value.find(
      (node) => !['add-step', 'note'].includes(node.type),
    ),
  );
  const addingStep = computed(() =>
    getSelectedNodes.value.find((node) => node.type === 'add-step'),
  );

  return { selectedStep, addingStep };
}
