<script setup lang="ts">
import type { Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';
import ContextInput from '~/components/editor/config/ContextInput.vue';

const modelValue = defineModel<Record<string, any>>();

defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  hasContext: {
    type: Boolean,
    default: false,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  formKey: {
    type: String,
    default: null,
  },
});

if (!modelValue.value) {
  modelValue.value = {};
}
</script>
<template>
  <div
    v-if="modelValue"
    class="flex flex-col gap-4 p-m bg-primary-50 shadow-sm rounded-xl border-primary-200 border items-start"
  >
    <div v-if="hasContext" class="contents">
      <ContextInput
        v-for="(opt, key) in option.metadata?.schema"
        :key="key"
        v-model="modelValue[key]"
        :form-key="`${formKey ?? option.key}.${key}`"
        :option="opt"
        class="w-full"
        :injectable="injectable"
        :size="size"
      />
    </div>
    <div v-else class="contents">
      <AppInput
        v-for="(opt, key) in option.metadata?.schema"
        :key="key"
        v-model="modelValue[key]"
        :form-key="`${formKey ?? option.key}.${key}`"
        :option="opt"
        class="w-full"
        :has-context="hasContext"
        :injectable="injectable"
        :size="size"
      />
    </div>
  </div>
</template>
