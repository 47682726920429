import { StepDefinition } from '../../types';

export default {
  key: 'send_message',
  name: 'Send Message',
  description: 'Sends a Slack message from your account.',
  icon: 'i-respell-slack',
  category: 'integrations',
  services: ['slack'],
  options: {
    channel: {
      key: 'channel',
      name: 'Channel',
      description: 'Which channel the message should be sent to.',
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'slack',
            member: 'get_channels',
          },
        },
        isSearchable: true,
      },
      listDepth: 0,
      value: null,
      isOptional: false,
    },
    content: {
      key: 'content',
      name: 'Message Content',
      description: 'The message you want to send.',
      type: 'text/plain',
      listDepth: 0,
      value: null,
      isOptional: false,
    }, 
    attachments: {
      key: 'attachments',
      name: 'Attachments',
      description: 'Any documents that you would like to add to the message',
      type: 'file/document',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-user',
      },
      value: [],
    },
    threadId: {
      key: 'threadId',
      name: 'Reply ID',
      description: 'Put a message ID here to reply to a message.',
      type: 'text/plain',
      listDepth: 0,
      value: null,
      isOptional: true,
    },
  },
  outputs: {},
} satisfies StepDefinition;
