import { TriggerDefinition } from '../../../definition';

export default {
  key: 'messages',
  name: 'New Slack Message',
  description: 'A new message is sent',
  icon: 'i-respell-slack',
  options: {
    channel: {
      key: 'channel',
      name: 'Channel',
      description: 'Which Slack channels to watch for new messages.',
      type: 'text/plain',
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'slack',
            member: 'get_channels',
          },
        },
      },
      isOptional: true,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    message: {
      key: 'message',
      name: 'Message',
      description: 'Text content of the message.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: '',
    },
    threadId: {
      key: 'threadId',
      name: 'Thread ID',
      description:
        'Unique identifier for the thread the message belongs to. Used to reply to the message.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    channelName: {
      key: 'channelName',
      name: 'Channel ID',
      description: 'Channel ID the message was sent to.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: '',
    },
    senderName: {
      key: 'senderName',
      name: 'Sender Name',
      description: 'Name of the Slack user that sent the message.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: '',
    },
  },
  example: {
    threadId: '1234567890',
    message: 'Hello, world!',
    channelName: 'general',
    senderName: 'John Doe',
  },
} satisfies TriggerDefinition;
