export const useTutorialStore = defineStore('tutorial', () => {
  const tutorialStep = ref(0);
  const hasTestedSpell = ref(false);
  const state = ref<'inactive' | 'active' | 'hidden'>('inactive');

  const restartTutorial = async () => {
    const spellStore = useSpellsStore();
    const userStore = useUserStore();

    const updatedMilestones = userStore.profile?.milestones.filter(
      (m) => m !== 'tutorial',
    );
    await userStore.updateProfile({
      milestones: updatedMilestones,
    });

    await spellStore.createSpell();
  };

  return {
    tutorialStep,
    restartTutorial,
    state,
    hasTestedSpell,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTutorialStore, import.meta.hot));
}
