import type {
  Edge,
  Graph,
  IntegrationLink,
  Spell,
  SpellPermission,
  Step,
  Team,
  Trigger,
  Workspace,
} from '@prisma/client';
import { GraphType } from '@prisma/client';

export type {
  Account,
  Bookmark,
  Edge,
  Folder,
  Graph,
  GraphRun,
  IntegrationLink,
  Invite,
  Member,
  Note,
  Profile,
  RunGroup,
  RuntimeError,
  Schedule,
  Session,
  Spell,
  SpellPermission,
  Step,
  StepRun,
  Subscription,
  Team,
  TemplateMetadata,
  Trigger,
  User,
  VerificationToken,
  Workspace,
} from '@prisma/client';

export {
  AccessLevel,
  FolderType,
  GraphInputType,
  GraphType,
  InviteStatus,
  MemberRole,
  Prisma,
  PrismaClient,
  RetentionLength,
  RunState,
  ScheduleState,
  SubscriptionType,
  TeamType,
} from '@prisma/client';

// Graph (+ Spell, Edges, Steps) joined with the Workspace.
export type GraphWithWorkspace = Graph & {
  spell: Spell & {
    workspace: Workspace
  };
  trigger: Trigger;
  steps: (Step & {
    integrations: IntegrationLink[];
  })[];
  edges: Edge[];
};

// Graph (+ Spell, Edges, Steps) joined with the Workspace.
export type PermissionWithTeam = SpellPermission & {
  team: Team;
};

export const DefaultSpellRelations = {
  creator: true,
  versions: {
    where: {
      type: GraphType.live,
    },
    select: {
      type: true,
      trigger: true,
      steps: {
        select: {
          key: true,
        },
      },
    },
  },
  templateMetadata: {
    select: {
      preview: true,
    },
  },
};

export const DefaultWorkspaceRelations = (userId: string) => {
  return {
    subscriptions: {
      where: {
        status: {
          in: ['active', 'trialing'],
        },
      },
    },
    teams: {
      // Teams have 3 types: workspace, personal and custom
      //
      // Users need to see the workspace teams to see the workspace-wide spells
      // Users each have their own personal "My Spells" team for each workspace, we don't want to include others'
      // Users need to see custom teams they are apart of and their members
      where: {
        members: {
          some: {
            userId: userId,
          },
        },
      },
      include: {
        members: true,
      },
    },
  };
};

export const DefaultIntegrationRelations = {
  user: {
    select: {
      profile: {
        select: {
          fullName: true,
        },
      },
    },
  },
};

export const DefaultSpellPermissions = (userId) => {
  return {
    spellPermissions: {
      include: {
        team: {
          include: {
            members: {
              where: {
                userId: userId,
              },
            },
          },
        },
      },
    },
  };
};

export const DefaultGraphRunPermissions = (userId) => {
  return {
    spell: {
      include: {
        ...DefaultSpellPermissions(userId),
      },
    },
  };
};

export const DefaultTriggerRelations = (userId) => {
  return {
    graph: {
      include: {
        spell: {
          include: {
            ...DefaultSpellPermissions(userId),
          },
        },
      },
    },
  };
};
