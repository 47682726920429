<script setup lang="ts">
import type { Variable } from '@respell/utils';
import { typeMap } from '@respell/utils';

const props = defineProps<{
  variable: Variable | null;
  size?: '2xs' | 'sm';
  onClick?: () => void;
  macroSelection?: KeyableDropdownItem | null;
  fieldSelection?: KeyableDropdownItem | null;
  actionItems?: KeyableDropdownItem[][];
  existingContext?: string | null;
  showContext?: boolean;
  hideIcon?: boolean;
  isContextualizing?: boolean;
  handleDelete?: () => void;
  isLoading?: boolean;
  readonly?: boolean;
}>();

const context = reactiveComputed(() => {
  return {
    content: props.existingContext,
  };
});

const needsContext = computed(
  () => !props.existingContext && props.showContext,
);

function handleCancel(callback: () => void) {
  context.content = props.existingContext;
  callback();
}
</script>
<template>
  <UDropdown
    v-if="variable"
    class="inline-flex"
    :disabled="readonly"
    :mode="readonly || showContext ? 'click' : 'hover'"
    :items="actionItems"
    :popper="{ placement: showContext ? 'bottom-start' : 'auto' }"
    :ui="{
      height: 'max-h-64 h-fit',
      item: { disabled: 'cursor-text select-text' },
      width: isContextualizing ? 'w-96' : 'w-auto',
    }"
  >
    <UButton
      :size="size"
      color="white"
      variant="solid"
      :label="variable?.name"
      :rounded="true"
      :class="{
        'outline outline-red-500 outline-1':
          needsContext || variable?.type === 'missing',
      }"
      :ui="{
        base:
          !onClick && !actionItems?.length
            ? 'cursor-default'
            : 'cursor-pointer',
        font: 'font-normal',
        rounded: 'rounded-full',
        padding: { '2xs': 'py-1 px-2' },
        color: {
          white: {
            solid:
              variable?.type === 'missing' ? 'text-red-500' : 'text-gray-900',
          },
        },
      }"
      @click="onClick && onClick()"
    >
      <template #leading>
        <UIcon
          v-if="!hideIcon"
          :name="
            variable?.type === 'missing'
              ? 'i-ph-info'
              : variable.listDepth > 0
                ? 'i-ph-brackets-square'
                : typeMap[variable.type]?.icon
          "
          :class="{
            'h-4 w-4': size === '2xs',
            'h-5 w-5': size === 'sm',
            'text-red-500': variable?.type === 'missing',
          }"
        />
      </template>
      <template #trailing>
        <UIcon
          v-if="isLoading"
          name="i-ph-arrows-clockwise"
          class="text-primary-400 animate-spin"
          :class="{
            'h-3 w-3': size === '2xs',
            'h-5 w-5': size === 'sm',
          }"
        />
        <div v-else-if="actionItems?.length" class="flex items-center gap-0.5">
          <div v-if="fieldSelection" class="contents">
            <p class="text-2xs text-primary-500">:</p>
            <p class="text-2xs text-primary-500">
              {{ fieldSelection.name }}
            </p>
          </div>
          <div v-if="macroSelection" class="contents">
            <UIcon
              name="i-ph-arrow-right-bold"
              class="h-2 w-2 text-green-500"
            />
            <p class="text-2xs text-green-500">
              {{ macroSelection.name }}
            </p>
          </div>

          <UIcon
            v-if="!readonly"
            name="i-ph-caret-down-fill"
            :class="[
              {
                'h-2.5 w-2.5': size === '2xs',
                'h-4 w-4': size === 'sm',
              },
            ]"
          />
        </div>
        <UButton
          v-if="handleDelete && !readonly"
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          size="2xs"
          @click="handleDelete"
        />
      </template>
    </UButton>
    <template #header="{ item }">
      <p class="font-semibold">{{ item.name }}</p>
    </template>
    <template #item="{ item }">
      <span class="truncate body-sm">{{ item.label }}</span>

      <UIcon
        v-if="
          item.key === macroSelection?.key || item.key === fieldSelection?.key
        "
        name="i-ph-check-circle-fill"
        class="flex-shrink-0 h-5 w-5 ml-auto text-primary-500"
      />
    </template>

    <template #context="{ item }">
      <UInput
        v-if="isContextualizing"
        v-model="context.content"
        size="xl"
        :disabled="readonly"
        variant="none"
        :padded="false"
        placeholder="Add context for this variable"
        class="w-full grow"
        :ui="{
          placeholder: 'text-gray-300',
          variant: {
            none: 'text-gray-800',
          },
          icon: {
            trailing: {
              pointer: 'pointer-events-auto',
            },
            leading: {
              pointer: 'pointer-events-auto',
            },
          },
        }"
        @click.stop
        @keydown.space.stop
        @keydown.enter.prevent="() => item.update(context.content)"
      >
        <template #leading>
          <UTooltip text="Cancel">
            <UButton
              :padded="false"
              variant="none"
              icon="i-ph-arrow-bend-up-left"
              class="cursor-pointer"
              :ui="{
                icon: {
                  base: 'text-primary-500',
                },
              }"
              @click.stop="() => handleCancel(item.click)"
            />
          </UTooltip>
        </template>
        <template #trailing v-if="!readonly">
          <UTooltip text="Update variable context" :shortcuts="['Enter']">
            <UButton
              color="primary"
              :padded="false"
              variant="none"
              icon="i-ph-arrow-circle-up-fill"
              :ui="{
                icon: {
                  base: 'text-primary-500',
                },
              }"
              class="cursor-pointer"
              @click.stop="() => item.update(context.content)"
            />
          </UTooltip>
        </template>
      </UInput>
      <span v-else class="flex gap-1" @click.stop="() => item.click()">
        <UIcon name="i-ph-magic-wand" class="text-gray-500 text-lg" />
        <p class="font-semibold text-gray-500">
          {{ needsContext ? 'Add Context' : 'Edit Context' }}
        </p>
      </span>
    </template>
  </UDropdown>
</template>
