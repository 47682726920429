import { type PostHogEvents } from '@respell/utils/tracking/types';
import { type PostHog } from 'posthog-js';

export function hasAccessToFeature(flagName: string) {
  const { $posthog } = useNuxtApp();
  const posthog = $posthog() as PostHog;

  const isEnabled = posthog.isFeatureEnabled(flagName);

  // If you haven't added the feature flag in posthog then it'll always be visible.
  return isEnabled === undefined ? false : isEnabled;
}

export function fatureFlagVariant(key: string, flagName: string) {
  const { $posthog } = useNuxtApp();
  const posthog = $posthog() as PostHog;

  const featureFlag = posthog.getFeatureFlag(flagName) === key;

  featureFlag;
}

export function trackEvent(
  event: PostHogEvents,
  properties?: Record<string, unknown>,
) {
  const { $posthog } = useNuxtApp();
  const posthog = $posthog() as PostHog;

  posthog.capture(event, properties);
}
