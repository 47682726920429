import { StepDefinition } from '../../types';

export default {
  key: 'create_company_note',
  name: 'Add Note To Company',
  description: 'Adds a note to a Hubspot Company',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    company: {
      key: 'company',
      name: 'Company',
      description: 'The company to associate the note with.',
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'hubspot',
            member: 'get_companies',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    note: {
      key: 'note',
      name: 'Note',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
      metadata: {
        isMultiline: true,
      },
    },
  },
  outputs: {},
} satisfies StepDefinition;
