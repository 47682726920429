export const categorySections = {
  'By Use Case': [
    {
      key: 'automated-research',
      name: 'Automated Research',
      icon: 'i-ph-magic-wand',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'analyze-spreadsheet',
      name: 'Analyze Spreadsheet',
      icon: 'i-ph-table',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'voice',
      name: 'Transcribe Audio',
      icon: 'i-ph-user-sound',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'text-summarization',
      name: 'Text Summarization',
      icon: 'i-ph-text-a-underline',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'productivity',
      name: 'Productivity',
      icon: 'i-ph-list-checks',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'files',
      name: 'Document Analysis',
      icon: 'i-ph-list-magnifying-glass',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'seo',
      name: 'SEO',
      icon: 'i-ph-magnifying-glass',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'automated-phone',
      name: 'Automated Phone Calls',
      icon: 'i-ph-phone',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
    {
      key: 'crm-automation',
      name: 'CRM Automation',
      icon: 'i-ph-users-three',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Use Case',
    },
  ],
  'By Role': [
    {
      key: 'marketing',
      name: 'Marketing',
      icon: 'i-ph-storefront',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'education',
      name: 'Education',
      icon: 'i-ph-pencil-simple',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'recruiting',
      name: 'Recruiting',
      icon: 'i-ph-users',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'sales',
      name: 'Sales',
      icon: 'i-ph-currency-circle-dollar',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'product',
      name: 'Product',
      icon: 'i-ph-rocket',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'data',
      name: 'Data',
      icon: 'i-ph-database',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'operations',
      name: 'Operations',
      icon: 'i-ph-hammer',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
    {
      key: 'Legal',
      name: 'Legal',
      icon: 'i-ph-scales',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Role',
    },
  ],
  'By Integration': [
    {
      key: 'salesforce',
      name: 'Salesforce',
      icon: 'i-logos-salesforce',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Integration',
    },
    {
      key: 'airtable',
      name: 'Airtable',
      icon: 'i-logos-airtable',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Integration',
    },
    {
      key: 'slack',
      name: 'Slack',
      icon: 'i-logos-slack-icon',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Integration',
    },
    {
      key: 'zapier',
      name: 'Zapier',
      icon: 'i-logos-zapier-icon',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Integration',
    },
    {
      key: 'hubspot',
      name: 'Hubspot',
      icon: 'i-fa6-brands-hubspot',
      gradient: 'linear-gradient(to right, #9d50bb, #6e48aa)',
      section: 'By Integration',
    },
  ],
};
export const categories = Object.fromEntries(
  Object.values(categorySections)
    .flat()
    .map((category) => [category.key, category]),
);

export const relevantTagsMap = {
  // Goal
  automate: ['productivity', 'automated-research', 'crm-automation'],
  connect: ['zapier', 'slack', 'airtable', 'salesforce', 'hubspot'],
  no_code: ['productivity', 'analyze-spreadsheet', 'text-summarization'],
  explore: [
    'productivity',
    'automated-research',
    'voice',
    'text-summarization',
  ],

  // Industry
  tech: ['product', 'data', 'engineering'],
  agency: ['marketing', 'sales', 'productivity'],
  finance: ['data', 'analyze-spreadsheet', 'files'],
  recruiting: ['recruiting', 'crm-automation'],
  healthcare: ['files', 'voice', 'text-summarization'],
  legal: ['legal', 'files', 'text-summarization'],
  retail: ['marketing', 'sales', 'seo'],

  // Role
  sales: ['sales', 'crm-automation', 'automated-phone'],
  marketing: ['marketing', 'seo', 'social-media'],
  operations: ['operations', 'productivity', 'analyze-spreadsheet'],
  hr: ['recruiting', 'crm-automation'],
  product: ['product', 'productivity', 'automated-research'],
  engineering: ['data', 'engineering', 'productivity'],
  design: ['product', 'productivity'],

  // Company Size
  '1-10': ['productivity', 'automated-research'],
  '10-50': ['productivity', 'crm-automation'],
  '50-100': ['productivity', 'crm-automation', 'analyze-spreadsheet'],
  '100+': [
    'productivity',
    'crm-automation',
    'analyze-spreadsheet',
    'automated-phone',
  ],

  // Tools (already included, but expanded)
  hubspot: ['hubspot', 'crm-automation', 'marketing'],
  salesforce: ['salesforce', 'crm-automation', 'sales'],
  airtable: ['airtable', 'analyze-spreadsheet', 'productivity'],
  slack: ['slack', 'productivity'],
  zapier: ['zapier', 'productivity'],

  // Referral Source (not directly related to categories, but included for completeness)
  x: ['social-media'],
  linkedin: ['social-media'],
  email: ['marketing'],
  friend: [],
  partner: [],
  directory: [],
  search: ['seo'],
};
