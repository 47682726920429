import type { SubscriptionType } from '@respell/database';

export const sourceToIcon = {
  GOOGLE_DRIVE: 'i-logos-google-drive',
  NOTION: 'i-logos-notion-icon',
};

enum FileFormatIcons {
  code = 'i-ph-file-code',
  file = 'i-ph-file',
  folder = 'i-ph-folder-simple',
  audio = 'i-ph-file-audio',
  pdf = 'i-ph-file-pdf',
  spreadsheet = 'i-ph-grid-nine',
  googleDocument = 'i-mdi-file-document',
  googleSpreadsheet = 'i-mdi-google-spreadsheet',
  googlePresentation = 'i-mdi-file-presentation-box',
  text = 'i-ph-file-txt',
  doc = 'i-ph-file-doc',
  mp3 = 'i-ph-file-audio',
  png = 'i-ph-file-png',
  jpg = 'i-ph-file-jpg',
  presentation = 'i-ph-browser',
}

export const fileFormatToIcon = {
  CSV: FileFormatIcons.spreadsheet,
  DOCX: FileFormatIcons.text,
  GOOGLE_DOCS: FileFormatIcons.googleDocument,
  GOOGLE_SHEETS: FileFormatIcons.googleSpreadsheet,
  GOOGLE_SLIDES: FileFormatIcons.googlePresentation,
  MD: FileFormatIcons.text,
  PNG: FileFormatIcons.png,
  MP3: FileFormatIcons.mp3,
  PDF: FileFormatIcons.pdf,
  JPG: FileFormatIcons.jpg,
  JPEG: FileFormatIcons.jpg,
  NOTION: FileFormatIcons.text,
  RTF: FileFormatIcons.text,
  TSV: FileFormatIcons.spreadsheet,
  TXT: FileFormatIcons.text,
  XLSX: FileFormatIcons.spreadsheet,
};

export const subscriptionTypeToDisplayName: Record<SubscriptionType, string> = {
  free: 'Respell Free',
  starter: 'Respell Starter',
  team: 'Respell Team',
  enterprise: 'Respell Enterprise',
  unknown: 'Unknown Plan',
  legacy: 'Respell Legacy',
};

export const subscriptionTypeToIconName: Record<SubscriptionType, string> = {
  free: 'i-ph-gift',
  starter: 'i-ph-lightning',
  team: 'i-ph-users',
  enterprise: 'i-ph-globe',
  unknown: 'i-ph-asterisk',
  legacy: 'i-ph-asterisk',
};

const baseFileSizeLimits: Record<string, string> = {
  'file/audio': '20MB',
  'file/document': '20MB',
  'file/image': '10MB',
  'file/spreadsheet': '20MB',
  'file/video': '100MB',
};

export const subscriptionTypeToFileSizeLimits: Record<
  SubscriptionType,
  string
> = {
  free: baseFileSizeLimits,
  starter: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  team: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  enterprise: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  unknown: baseFileSizeLimits,
  legacy: baseFileSizeLimits,
};
