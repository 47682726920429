<script setup lang="ts">
import type { Graph, GraphRun } from '@respell/database';
import AppInput from '~/components/app/AppInput.vue';
import ReviewModal from '~/components/modals/ReviewModal.vue';

const props = defineProps<{
  run: GraphRun;
  graph: Graph;
  size?: 'sm' | 'md';
}>();

const spellStore = useSpellsStore();
const modal = useModal();
const { isRunning } = storeToRefs(spellStore);

const outputs = computed(() =>
  Object.entries(props.graph?.outputs ?? {})
    .filter(
      ([key, output]) =>
        !output.metadata?.forReview && !!props?.run?.outputs?.[key],
    )
    .reduce((acc, [key, output]) => {
      acc[key] = {
        ...output,
        value: props?.run?.outputs?.[key],
      };
      return acc;
    }, {}),
);

const handleReview = () => {
  modal.open(ReviewModal, {
    runId: props.run.id,
  });
};
</script>
<template>
  <UCard
    class="w-full"
    :ui="{
      rounded: 'rounded-2xl',
      divide: 'divide-y-0',
      header: {
        padding: '!pb-0',
      },
      body: {
        base: `flex flex-col gap-${size === 'sm' ? '3' : '4'}`,
        padding: size === 'sm' ? 'sm:pt-4' : 'sm:p-6',
      },
    }"
  >
    <template #header>
      <span class="flex justify-start gap-2">
        <span
          class="bg-primary-200 rounded-full flex"
          :class="size === 'sm' ? 'p-1.5' : 'p-2'"
        >
          <UIcon
            name="i-ph-sparkle-fill"
            class="text-primary-500"
            :class="size === 'sm' ? 'text-lg' : 'text-xl'"
          />
        </span>
        <p :class="size === 'sm' ? 'subtitle' : 'title'">
          {{ isRunning ? 'Crafting the perfect response...' : 'Output' }}
        </p>
      </span>
    </template>
    <div v-if="Object.keys(outputs ?? {}).length" class="contents">
      <AppInput
        v-for="(output, key) in outputs"
        :key="key"
        :model-value="output.value"
        owner-type="GraphRun"
        :owner-id="run.id"
        :option="output"
        :size="size"
        readonly
      />
    </div>
    <UAlert
      v-else-if="run?.state === 'paused'"
      icon="i-ph-warning-circle"
      color="gray"
      variant="soft"
      title="Awaiting review"
      description="Before continuing, review the requested items in your inbox"
      :actions="[
        {
          label: 'Begin review',
          color: 'gray',
          variant: 'outline',
          block: true,
          size: 'lg',
          click: handleReview,
        },
      ]"
      :ui="{
        title: 'font-bold',
      }"
    />
    <UProgress
      v-else-if="run?.state === 'inProgress'"
      color="primary"
      size="sm"
      animation="carousel"
    />
    <UAlert
      v-else
      title="No outputs"
      description="Check the run details below for more info"
      color="gray"
      variant="soft"
      :ui="{
        title: 'font-bold',
      }"
    />
  </UCard>
</template>
