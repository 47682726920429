import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new_file',
  name: 'New File',
  icon: 'i-respell-one-drive',
  description: 'A file is created in OneDrive.',
  options: {},
  outputs: {
    fileName: {
      key: 'fileName',
      type: 'text/plain',
      description: 'The name of the file.',
      name: 'File Name',
      isOptional: false,
      listDepth: 0,
      value: null
    },
    file: {
      key: 'file',
      type: 'file/document',
      description: 'The file that was created.',
      name: 'File',
      isOptional: false,
      listDepth: 0,
      value: null
    },
  },
} satisfies TriggerDefinition;