import { config } from '@respell/utils/config';
import * as Sentry from '@sentry/browser';
import posthog from 'posthog-js';
import { config, isProd, isStaging } from '@respell/utils/config';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  // Set PostHog host value if it is empty
  if (!runtimeConfig.public.posthogHost) {
    runtimeConfig.public.posthogHost = 'https://us.posthog.com';
  }

  const posthogClient = posthog.init(config.POST_HOG_TOKEN, {
    session_recording: {
      maskAllInputs: false,
    },
    api_host: runtimeConfig.public.posthogHost,
  });

  if (isProd || isStaging) {
    Sentry.init({
      dsn: config.CLIENT_SENTRY_DSN,
      integrations: [
        posthog.sentryIntegration({
          organization: 'respell',
          projectId: 4504783725527040,
          severityAllowList: ['error', 'info'],
        }),
      ],
      environment: config.RESPELL_ENV,
    });
  }

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
