import { StepDefinition } from '../../types';

export default {
  key: 'create_drive_doc',
  name: 'Add Document',
  description: 'Creates a document in your Drive.',
  icon: 'i-respell-google',
  category: 'integrations',
  services: ['google-drive'],
  options: {
    folder: {
      key: 'folder',
      name: 'Folder',
      isOptional: true,
      value: null,
      listDepth: 0,
      description: 'The folder to which the file should be uploaded',
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'google-drive',
            member: 'list_drive_folders',
          },
        },
      },
    },
    fileName: {
      key: 'fileName',
      name: 'File Name',
      description: 'The name of the file to create.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    content: {
      key: 'content',
      name: 'Content',
      description: 'The content to insert into the file.',
      isOptional: false,
      type: 'text/plain',
      metadata: {
        isMultiline: true,
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    fileId: {
      key: 'fileId',
      name: 'File ID',
      description: 'The ID of the file that was created.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
