import { StepDefinition } from '../../types';

export default {
  key: 'gcp_upload_file',
  name: 'Upload File',
  description: 'Uploads a file using to a bucket in Google Cloud Platform',
  icon: 'i-logos-google-cloud',
  category: 'integrations',
  services: ['gcp'],
  options: {
    file: {
      type: 'file/document',
      isOptional: false,
      description: 'The file to upload.',
      name: 'File',
      listDepth: 0,
      value: null,
      key: 'file',
    },
    bucket_id: {
      key: 'bucket_id',
      name: 'Bucket',
      description: 'The bucket that you would like to upload the file to',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
      metadata: {
        options: {
          reference: {
            namespace: 'gcp',
            member: 'list_buckets',
          },
        },
      },
    },
    filename: {
      key: 'filename',
      name: 'File Name',
      description:
        "The name of the file on the destination. Include slashes for directories (e.g. 'directory/other-directory/my-file.txt')",
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
