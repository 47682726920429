export default defineAppConfig({
  ui: {
    primary: 'purple',
    gray: 'cool',
    verticalNavigation: {
      base: 'my-s justify-start gap-4 my-m transition-colors',
      active: 'text-primary-500',
      size: 'text-lg',
      icon: {
        base: 'w-6 h-6',
        active: 'text-primary-500',
      },
    },
    container: {
      base: 'h-full flex-1 relative',
      padding: 'p-4 sm:p-6 lg:p-8',
    },
    modal: {
      rounded: 'rounded-2xl',
    },
    selectMenu: {
      height: 'h-fit max-h-60',
      width: 'min-w-full w-fit max-w-60',
      option: {
        selected: 'bg-primary-500 text-white',
        selectedIcon: {
          base: 'text-primary-300 cursor-pointer',
        },
      },
      default: {
        selectedIcon: '',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-ph-arrow-clockwise',
        trailingIcon: 'i-ph-caret-down',
      },
    },
    input: {
      icon: {
        loading: 'text-primary-500',
      },
      default: {
        loadingIcon: 'i-ph-arrow-clockwise',
      },
    },
    button: {
      base: 'transition-all',
      font: 'font-bold',
      padding: { xl: 'px-5 py-2' },
      default: {
        loadingIcon: 'i-ph-arrow-clockwise',
      },
    },
    alert: {
      default: {
        icon: 'i-ph-info-bold',
      },
    },
    notification: {
      default: {
        icon: 'i-ph-info-bold',
      },
    },
    accordion: {
      item: {
        base: 'w-full',
      },
    },
    formGroup: {
      label: { base: 'font-bold', required: 'after:text-purple-600' },
      container: 'mt-2 pt-1 w-full',
    },
    // Make z-index greater than unthread widget
    notifications: {
      wrapper: 'z-[2147483001]',
      container: 'w-full',
    },
    table: {
      wrapper: 'border border-gray-200 rounded-xl mt-m',
      divide: 'divide-grey-200 dark:divide-gray-800',
      th: {
        color: 'dimmed dark:text-white',
        size: 'text-md',
      },
      td: {
        size: 'text-md',
      },
      default: {
        sortAscIcon: 'i-ph-sort-ascending',
        sortDescIcon: 'i-ph-sort-descending',
        sortButton: {
          icon: 'i-ph-arrows-down-up',
        },
        loadingState: {
          icon: 'i-ph-arrows-clockwise',
        },
        emptyState: {
          icon: 'i-ph-database',
        },
      },
    },
    pagination: {
      base: 'flex items-center justify-center w-12 h-12 rounded-lg ring-transparent shadow-none',
      default: {
        size: 'lg',
        firstButton: {
          icon: 'i-ph-caret-double-left',
        },
        lastButton: {
          icon: 'i-ph-caret-double-right',
        },
        prevButton: {
          icon: 'i-ph-caret-left',
        },
        nextButton: {
          icon: 'i-ph-caret-right',
        },
      },
    },
    dropdown: {
      width: 'w-fit',
      item: { base: 'justify-start w-full' },
    },
    tooltip: {
      color: 'text-white',
      background: 'bg-gray-900 ',
    },
    card: {
      ring: 'ring-gray-200',
    },
  },
});
