import { TriggerDefinition } from '../../../definition';

export default {
  key: 'apollo-accounts',
  name: 'New Account',
  description: 'An account is created in Apollo.',
  options: {},
  icon: 'i-respell-apollo',
  outputs: {
    account: {
      metadata: {
        schema: {
          name: {
            key: 'name',
            name: 'Name',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          domain: {
            key: 'domain',
            name: 'Domain',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          phone_number: {
            key: 'phone_number',
            name: 'Phone Number',
            isOptional: true,
            type: 'text/phone-number',
            listDepth: 0,
            value: '',
          },
          raw_address: {
            key: 'raw_address',
            name: 'Raw Address',
            isOptional: true,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          facebook_url: {
            key: 'facebook_url',
            name: 'Facebook URL',
            isOptional: true,
            type: 'text/url',
            listDepth: 0,
            value: '',
          },
          linkedin_url: {
            key: 'linkedin_url',
            name: 'LinkedIn URL',
            isOptional: true,
            type: 'text/url',
            listDepth: 0,
            value: '',
          },
          twitter_url: {
            key: 'twitter_url',
            name: 'Twitter URL',
            isOptional: true,
            type: 'text/url',
            listDepth: 0,
            value: '',
          },
        }
      },
      name: 'Account',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: {},
      key: 'account'
    }
  },
  example: {
    account: {
      name: 'Acme, Inc.',
      domain: 'acme.com',
      phone_number: '123-456-7890',
      raw_address: '123 Example Ln. Suite A, San Francisco, CA 94102',
      facebook_url: 'https://www.facebook.com/acme',
      linkedin_url: 'https://www.linkedin.com/in/acme/',
      twitter_url: 'https://twitter.com/acme',
    }
  },
} satisfies TriggerDefinition;
