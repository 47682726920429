<script setup lang="ts">
import { typeMap } from '@respell/utils';
import { format, isSameDay, sub, type Duration } from 'date-fns';
import AppTextEditor from '~/components/app/AppTextEditor.vue';
import VariableNode from '~/components/app/VariableNode.vue';
import DatePicker from '~/components/editor/config/DatePicker.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import { variableRegex } from '~/stores/canvas';

const modelValue = defineModel<string | object>();
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const { emitFormChange } = useFormGroup();

const label = computed(() => {
  switch (props.type) {
    case 'date':
      return modelValue.value
        ? format(modelValue.value, 'MMM d, yyy')
        : 'Choose a date';
    case 'datetime':
      return modelValue.value
        ? format(modelValue.value, 'MMM d, yyy, h:mm a')
        : 'Choose a date and time';
    case 'time':
      return modelValue.value
        ? format(modelValue.value, 'h:mm a')
        : 'Choose a time';
    case 'daterange':
      return modelValue.value
        ? `${format(modelValue.value?.start, 'MMM d, yyy')} - ${format(
            modelValue.value?.end,
            'MMM d, yyy',
          )}`
        : 'Choose a date range';
    default:
      return '';
  }
});

const mode = computed(() => {
  switch (props.type) {
    case 'date':
      return 'date';
    case 'datetime':
      return 'dateTime';
    case 'daterange':
      return 'daterange';
    case 'time':
      return 'time';
    default:
      return 'date';
  }
});

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value?.match(variableRegex);
    return matches;
  }
  return false;
});

// daterange

const ranges = [
  { label: 'Last 7 days', duration: { days: 7 } },
  { label: 'Last 14 days', duration: { days: 14 } },
  { label: 'Last 30 days', duration: { days: 30 } },
  { label: 'Last 3 months', duration: { months: 3 } },
  { label: 'Last 6 months', duration: { months: 6 } },
  { label: 'Last year', duration: { years: 1 } },
];

function isRangeSelected(duration: Duration) {
  return (
    modelValue.value &&
    isSameDay(modelValue.value?.start, sub(new Date(), duration)) &&
    isSameDay(modelValue.value?.end, new Date())
  );
}

function selectRange(duration: Duration) {
  modelValue.value = { start: sub(new Date(), duration), end: new Date() };
}
</script>
<template>
  <AppTextEditor
    v-if="props.readonly"
    :model-value="label"
    :type="type"
    readonly
  />
  <VariablePicker
    v-else-if="injectable"
    v-model="modelValue"
    :type="type"
    class="w-full"
    @update:model-value="emitFormChange"
  >
    <template #button>
      <UButton
        trailing-icon="i-ph-caret-down"
        color="white"
        block
        variant="solid"
        size="lg"
        :ui="{ font: 'font-normal', block: 'justify-between' }"
      >
        <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
        <span v-else class="flex flex-row items-center gap-2">
          <UIcon :name="typeMap[type].icon" class="w-5 h-5 text-gray-500" />
          <span>{{ label }}</span>
        </span>
      </UButton>
    </template>
    <template #calendar>
      <DatePicker
        v-model="modelValue"
        :mode="mode"
        @update:model-value="emitFormChange"
      />
    </template>
  </VariablePicker>
  <UPopover v-else :popper="{ placement: 'bottom-start' }">
    <UButton
      :label="label"
      size="xl"
      variant="solid"
      color="white"
      :icon="typeMap[type].icon"
      block
      :ui="{
        font: 'font-normal',
        block: 'justify-start',
        padding: { xl: 'px-3' },
      }"
    />

    <template #panel="{ close }">
      <div
        v-if="mode === 'daterange'"
        class="flex items-center sm:divide-x divide-gray-200 dark:divide-gray-800"
      >
        <div class="hidden sm:flex flex-col py-4 w-48">
          <UButton
            v-for="(range, index) in ranges"
            :key="index"
            :label="range.label"
            color="gray"
            variant="ghost"
            class="rounded-none px-6"
            :class="[
              isRangeSelected(range.duration)
                ? 'bg-gray-100 dark:bg-gray-800'
                : 'hover:bg-gray-50 dark:hover:bg-gray-800/50',
            ]"
            truncate
            @click="selectRange(range.duration)"
          />
        </div>
        <DatePicker
          v-model="modelValue"
          :mode="mode"
          @close="close"
          @update:model-value="emitFormChange"
        />
      </div>

      <DatePicker
        v-else
        v-model="modelValue"
        :mode="mode"
        @close="close"
        @update:model-value="emitFormChange"
      />
    </template>
  </UPopover>
</template>
