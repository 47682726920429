<script setup lang="ts">
import type { IntegrationLink } from '@respell/database';
import type { Json, Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';

const integrationStore = useIntegrationStore();
const canvasStore = useCanvasStore();

const props = defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  ownerId: {
    type: String,
    default: null,
  },
  ownerType: {
    type: String,
    default: 'Spell',
  },
  formKey: {
    type: String,
    default: null,
  },
  linkedAccount: {
    type: Object as PropType<IntegrationLink>,
    default: null,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  localOnly: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel<any>();
const searchQuery = ref<string>('');
const debouncedQuery = refDebounced(searchQuery, 500);

const { selectedStep } = useSelectedStep();
const { linkedAccounts } = storeToRefs(integrationStore);
const { trigger } = storeToRefs(canvasStore);

const { inEditor } = useRouteName();
const { metadata, conditions } = props.option;

// Determine which field to hydrate based on the presence of a reference
const toHydrate = computed(() => {
  return metadata?.options?.reference
    ? 'options'
    : metadata?.schema?.reference
      ? 'schema'
      : null;
});

// Get the reference object from the metadata
const reference = computed(() => {
  if (toHydrate.value) {
    return metadata?.[toHydrate.value]?.reference;
  }
  return null;
});

const serviceAgnostic = computed(() => {
  return !!reference.value && !('namespace' in reference.value);
});

// Get the linked account from the selected step and reference namespace
const linkedAccountId = computed(() => {
  const linkedAccount = props.linkedAccount
    ? props.linkedAccount
    : inEditor.value
      ? selectedStep.value?.data.integrations?.find(
          (link: IntegrationLink) =>
            link.service === reference.value?.namespace,
        )
      : linkedAccounts.value.find(
          (link: IntegrationLink) =>
            link.service === reference.value?.namespace,
        );
  return linkedAccount?.id;
});

// Get the conditional dependencies (parents) from the selected step
const dependencies = computed(() =>
  conditions?.length
    ? conditions.map((condition) => {
        return props.linkedAccount
          ? trigger.value?.options[condition.reference]
          : selectedStep.value?.data.options[condition.reference];
      })
    : [],
);

const isReady = computed(
  () =>
    useConditional(props.option).value &&
    (linkedAccountId.value || serviceAgnostic.value),
);

// Hydrate the option from context
const option = computed(() => {
  // Check if all necessary data are available
  if (reference.value && toHydrate.value && context.value && isReady.value) {
    // Clone the option prop to avoid mutating the original
    const hydratedOption = useCloned(props.option).cloned.value;
    if (hydratedOption.metadata) {
      // If the integration action returned an array for a schema reference,
      // convert it into a text/plain schema definition object first
      if (toHydrate.value === 'schema' && Array.isArray(context.value)) {
        hydratedOption.metadata.schema = Object.fromEntries(
          context.value.map((item: Variable) => [item.key, item]),
        ) as Json;
      } else {
        hydratedOption.metadata[toHydrate.value] = context.value;
      }
    }

    return hydratedOption;
  } else {
    return props.option;
  }
});

const isSearch = computed(() => {
  return reference.value?.arguments?.[0]?.parameter === 'query';
});

const contextOptions = computed(() =>
  props.linkedAccount
    ? trigger.value?.options
    : selectedStep.value?.data.options,
);

const {
  data: context,
  pending: isFetching,
  refresh,
} = await useAsyncCache(
  `context/${serviceAgnostic.value ? 'account' : linkedAccountId.value}/${props.linkedAccount ? 'trigger' : selectedStep.value?.data.slug ?? 'postAuth'}/${props.option.key}`,
  () =>
    canvasStore.fetchContext({
      options: contextOptions.value,
      linkedAccountId: linkedAccountId.value,
      reference: reference.value,
      isReady: isReady.value,
      query: debouncedQuery.value,
    }),
  {
    watch: isSearch.value
      ? [linkedAccountId, dependencies, debouncedQuery]
      : [linkedAccountId, dependencies],
  },
);

// This watcher clears the value when the dependencies change.
// Only if a new parent option exists do we refresh the context
watchDeep([linkedAccountId, dependencies], async () => {
  modelValue.value = null;
});
</script>
<template>
  <div v-if="!reference || isReady" class="relative">
    <div
      v-if="isFetching && !isSearch"
      class="flex flex-col items-start gap-3 mt-m"
    >
      <USkeleton class="h-4 w-1/4" />
      <USkeleton class="h-3 w-1/2" />
      <USkeleton class="h-10 w-full" />
    </div>

    <AppInput
      v-else
      v-model="modelValue"
      v-model:query="searchQuery"
      v-bind="{
        option,
        isSearching: isSearch && isFetching,
        hasError,
        formKey,
        ownerType,
        ownerId,
        injectable,
        size,
        localOnly,
      }"
      has-context
    />
    <UButton
      v-if="reference && isReady"
      variant="ghost"
      icon="i-ph-arrows-clockwise"
      color="gray"
      class="absolute opacity-50 top-3 right-3"
      :class="{ 'animate-spin': isFetching }"
      @click="refresh"
    />
  </div>
</template>
