import { StepDefinition } from '../../types';

export default {
  key: 'generate_text',
  name: 'Generate Text with AI',
  description: 'Generates text from a prompt using AI.',
  icon: 'i-respell-generate-text',
  documentationLink:
    'https://docs.respell.ai/steps-reference/text-tools/generate-text',
  category: 'text',
  groups: {
    basic: {
      key: 'basic',
      name: 'Basic',
    },
    advanced: {
      key: 'advanced',
      name: 'Advanced',
    },
  },
  options: {
    model: {
      key: 'model',
      name: 'Model',
      description: 'The base model you want to use.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        group: 'basic',
        preventInject: true,
        options: {
          reference: {
            member: 'get_models',
          },
        },
      },
      value: 'azure/gpt-4-8k',
    },
    prompt: {
      key: 'prompt',
      name: 'Prompt',
      description:
        'The prompt (instruction) to send to the AI. This could be a question or a task you give the AI to complete.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        group: 'basic',
        hint: 'Create an outline for my paper...\nWrite a poem in the style of...\nAnalyze the tone of this email...',
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: null,
    },
    temperature: {
      key: 'temperature',
      name: 'Temperature',
      description:
        'How creative the AI should be in its response. Higher temperatures will make the AI more creative, but it may not follow the prompt entirely. Set temperature low for tasks where you want consistent responses.',
      isOptional: true,
      type: 'number/decimal',
      metadata: {
        group: 'advanced',
        preventInject: true,
        min: 0.0,
        max: 2.0,
      },
      listDepth: 0,
      value: 0.7,
    },
    maxTokens: {
      key: 'maxTokens',
      name: 'Max Tokens',
      description: 'Maximum number of tokens to generate.',
      isOptional: true,
      type: 'number/integer',
      listDepth: 0,
      metadata: {
        group: 'advanced',
        preventInject: true,
        min: 1,
        max: 4000,
      },
      value: 4000,
    },
    isJson: {
      key: 'isJson',
      name: 'JSON Mode',
      description: 'Whether the output should be JSON.',
      isOptional: true,
      type: 'boolean',
      listDepth: 0,
      value: false,
      conditions: [
        {
          reference: 'model',
          type: 'contains',
          value: 'azure',
          or: [
            {
              reference: 'model',
              type: 'contains',
              value: 'gemini',
            },
            {
              reference: 'model',
              type: 'contains',
              value: 'openai',
            },
          ],
        },
      ],
      metadata: {
        group: 'advanced',
        preventInject: true,
      },
    },
    system_instruction: {
      key: 'system_instruction',
      name: 'System Instruction',
      description: 'How should the model behave with a given prompt?',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        group: 'advanced',
        max: 128000,
        isMultiline: true,
        isPrompt: true,
      },
      value: 'You are a helpful assistant.',
    },
  },
  outputs: {
    response: {
      key: 'response',
      name: 'Response',
      description: 'The response from the AI.',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
