import type { Notification } from '#ui/types';

export const runLimitReached: Partial<Notification> = {
  title: 'Run limit reached.',
  id: 'run-limit-reached',
  color: 'rose',
  description:
    'You have reached your monthly run limit. Please upgrade to continue.',
  actions: [
    {
      label: 'View Plans',
      to: '/account/billing',
      block: true,
    },
  ],
};

export const bulkRunLimitReached: Partial<Notification> = {
  title: 'Bulk run limit reached.',
  id: 'bulk-run-limit-reached',
  color: 'rose',
  description:
    'This bulk run contains more runs than your remaining monthly limit. Please upgrade to continue.',
  actions: [
    {
      label: 'View Plans',
      to: '/account/billing',
      block: true,
    },
  ],
};
