import { TriggerDefinition } from '../../../definition';

export default {
  key: 'new-row',
  name: 'New Rows',
  description: 'A new row is created in Google Sheets.',
  icon: 'i-respell-google',
  options: {
    sheet: {
      key: 'sheet',
      name: 'Sheet',
      description: 'The sheet to add a row to. Select the Sheet from Google Drive and we’ll load the columns in for you.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_sheets',
          },
        },
      },
      value: null,
    },
    worksheet: {
      key: 'worksheet',
      name: 'Worksheet',
      description: 'The worksheet to insert into.',
      isOptional: false,
      type: 'text/plain',
      conditions: [
        {
          reference: 'sheet',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_worksheets_name',
            arguments: [
              {
                namespace: 'options',
                member: 'sheet',
                parameter: 'sheetId',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: '0',
    },
  },
  outputs: {
    fields: {
      key: 'fields',
      name: 'New Row Details',
      description: 'Details of the newly created row.',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            namespace: 'google-sheet',
            member: 'list_headers_name',
            arguments: [
              {
                namespace: 'options',
                member: 'sheet',
                parameter: 'sheetId',
              },
              {
                namespace: 'options',
                member: 'worksheet',
                parameter: 'worksheetId',
              },
            ],
          },
        },
      },
      value: {},
    },
  },
} satisfies TriggerDefinition;
