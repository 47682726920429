import type { User as BaseUser, Profile, Workspace } from '@respell/database';
import { PostHogEvents } from '@respell/utils/tracking/types';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { trackEvent } from '~/util/posthog';
import { useSpellsStore } from './spells';
import { useWorkspaceStore } from './workspaces';

type User = BaseUser & {
  profile: Profile;
};

export const allMilestones = [
  'questionnaire',
  'tutorial',
  'createSpell',
  'testSpell',
  'publishSpell',
  'shareSpell',
  'joinedSlack',
] as const;

export const useUserStore = defineStore('user', {
  state: () => ({
    user: undefined as User | undefined,
    profile: undefined as Profile | undefined,
    relevantTags: [],
    isOnV1: false,
  }),
  // persist: true,
  getters: {
    hasCompletedMilestones(): boolean | undefined {
      return (
        this.profile?.milestones &&
        allMilestones.every((milestone) =>
          this.profile?.milestones.includes(milestone),
        )
      );
    },
    activeWorkspace(): Workspace | undefined {
      const workspaceStore = useWorkspaceStore();
      return workspaceStore.workspace;
    },
  },
  actions: {
    async login(credentials: { email: string; password: string }) {
      const { signIn } = useAuth();
      const response = await signIn('credentials', {
        ...credentials,
        redirect: false,
      });

      if (response?.status === 200) {
        trackEvent(PostHogEvents.UserLoggedIn, {
          email: credentials.email,
        });
      }

      return response;
    },
    async register(credentials: {
      fullName: string;
      email: string;
      password: string;
    }) {
      const response = await $api(`/api/register`, {
        method: 'POST',
        body: credentials,
      });

      const userId = response.user.id;
      const referrerId = response.user.referrerId;
      const isAdmin = response.user.isAdmin;

      if (response?.success) {
        trackEvent(PostHogEvents.UserSignedUp, {
          userId,
          isAdmin,
          email: credentials.email,
          referrerId: referrerId ? referrerId : 'No referral',
        });
      }

      return response;
    },
    async loadUser() {
      const { data: user, error } = await useApi<User & { isOnV2: boolean }>(
        '/api/me',
      );
      if (error.value || !user.value) {
        console.error(error);
      } else {
        const { isOnV1, ...rest } = user.value;

        this.user = rest;
        this.profile = rest.profile;
        this.isOnV1 = isOnV1;

        await this.handleCaslAuthorization();
      }

      return this.user;
    },
    async handleCaslAuthorization() {
      const caslStore = useCaslStore();
      caslStore.updateRules();
    },
    logout() {
      const workspaceStore = useWorkspaceStore();
      const spellStore = useSpellsStore();

      const { signOut } = useAuth();

      workspaceStore.workspaceId = undefined;
      spellStore.recentSpellIds = [];

      signOut();
    },
    async updateProfile(updates: Partial<Profile>) {
      const { data: user, error } = await useApi<User & { profile: Profile }>(
        `/api/users/${this.user?.id}/profile`,
        {
          method: 'patch',
          body: updates,
        },
      );
      if (error.value) {
        console.error(error);
      } else {
        this.user = user.value;
        this.profile = user.value?.profile;
        trackEvent(PostHogEvents.UserCompletedTutorial, {
          userId: this.user?.id,
          updates,
        });
      }
    },
    async requestPasswordUpdate(email: string) {
      return await client.post('/auth/reset-password-token', { email });
    },
    async updatePassword(email: string, newPassword: string, token: string) {
      return await client.put('/auth/password', { email, newPassword, token });
    },
    async fetchUser(id: string | undefined) {
      const { data: user, error } = await useApi(`/api/users/${id}`, {
        method: 'get',
      });
      if (error) {
        throw error;
      } else {
        return user;
      }
    },
    async markAsComplete(step: string) {
      if (this.profile && !this.profile.milestones.includes(step)) {
        const updatedMilestones = [...this.profile.milestones, step];

        await this.updateProfile({
          milestones: updatedMilestones,
        });
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
