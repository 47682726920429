import { TranscriptModel } from '@respell/integrations/fireflies/models';
import { StepDefinition } from '../../types';

export default {
  key: 'get_transcript',
  name: 'Get Transcript',
  description: 'Retrieves infromation about a particular fireflies transcript',
  icon: 'i-respell-fireflies', // FIXME: This should currently error out the UI due to tailwind complaining
  category: 'integrations',
  services: ['fireflies'],
  options: {
    transcript_id: {
      key: 'transcript_id',
      name: 'Transcript ID',
      description: 'The id of the transcript',
      isOptional: false, // TODO: Ask jake if this should be true or false
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    transcript: {
      key: 'transcript',
      name: 'Transcript',
      description: 'object',
      isOptional: false,
      type: 'object',
      metadata: {
        schema: TranscriptModel.schema,
      },
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
