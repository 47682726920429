import { abilitiesPlugin } from '@casl/vue';
import type { User } from '@respell/database';
import { defineAbility } from '~/util/defineAbility';

export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia } = nuxtApp;

  const userStore = useUserStore($pinia);
  const { user } = storeToRefs(userStore);

  const ability = defineAbility(user.value as User);

  nuxtApp.vueApp.use(abilitiesPlugin, ability);
});
