import { Dictionary } from './types';

// Declare the global env object for Typescript.
declare const process: {
  env: Dictionary<string>;
};
// Typings of every configuration key currently accessible.
type ConfigKey =
  // Each secret entry in Doppler should be added here.
  | 'ENV'
  | 'DEEPGRAM_API_KEY'
  | 'AUTH_ORIGIN'
  | 'AWS_ACCESS_KEY_ID'
  | 'AWS_REGION'
  | 'AWS_SECRET_ACCESS_KEY'
  | 'CODETHREAD_API_KEY'
  | 'GOOGLE_OCR_PROCESSOR_NAME'
  | 'OPENAI_API_KEY'
  | 'GOOGLE_CLIENT_ID'
  | 'GOOGLE_CLIENT_SECRET'
  | 'NANGO_KEY'
  | 'NEXTAUTH_SECRET'
  | 'AUTH_NO_ORIGIN'
  | 'DATABASE_URL'
  | 'REDIS_URL_RESEARCH_AGENT'
  | 'REDIS_PASSWORD_RESEARCH_AGENT'
  | 'RESEARCH_AGENT_URL'
  | 'NEXTAUTH_URL'
  | 'RESPELL_ENV'
  | 'CLIENT_SENTRY_DSN'
  | 'GCP_PROJECT_ID'
  | 'CLIENT_SENTRY_SERVER_DSN'
  | 'NEXTAUTH_URL_INTERNAL'
  | 'OKTA_CLIENT_ID'
  | 'OKTA_CLIENT_SECRET'
  | 'OKTA_ISSUER'
  | 'TEMPORAL_ADDRESS'
  | 'TEMPORAL_ADDRESS_BULK'
  | 'TEMPORAL_NAMESPACE'
  | 'TEMPORAL_NAMESPACE_BULK'
  | 'SENTRY_API_DSN'
  | 'TEMPORAL_WORKFLOWS_PER_INSTANCE'
  | 'TEMPORAL_CA_KEY'
  | 'E2B_API_KEY'
  | 'TEMPORAL_CA_PEM'
  | 'SERPER_API_KEY'
  | 'RESPELL_APP_URL'
  | 'LAKERA_API_KEY'
  | 'ANTHROPIC_API_KEY'
  | 'REDIS_URL'
  | 'REDIS_PASSWORD'
  | 'REDIS_PORT'
  | 'SCRAPING_BEE_KEY'
  | 'CARBON_API_KEY'
  | 'SENTRY_WORKER_DSN'
  | 'STRIPE_CUSTOMER_PORTAL_URL'
  | 'STRIPE_PRICING_TABLE_ID'
  | 'STRIPE_SECRET_KEY'
  | 'STRIPE_PUBLIC_KEY'
  | 'STRIPE_WEBHOOK_SECRET'
  | 'GEMINI_API_KEY'
  | 'API_URL'
  | 'COHERE_API_KEY'
  | 'PORT'
  | 'POST_HOG_TOKEN'
  | 'PRISMA_PULSE_API_KEY'
  | 'EDENAI_API_KEY'
  | 'EDENAI_OCR_ENDPOINT'
  | 'AZURE_OPENAI_ENDPOINT'
  | 'AZURE_OPENAI_API_KEY'
  | 'CONVERT_API_KEY'
  | 'HUBSPOT_API_KEY';

// Wrap the config variable dictionary with typings.
type Config = {
  readonly [key in ConfigKey]: string;
};

// Export a typed object of the configuration secrets injected by Doppler.
// If this is run locally, Doppler injects the variables directly into process.env.
// If this is run in a cloud service, Doppler injects all the variables into one variable called DOPPLER_ENV as Json.
//
// e.g.
// const client = new GoogleClient(key: config.GOOGLE_API_KEY);
// Note: this can't be imported at the workflow level in Temporal, it must be imported at the activity level.
export const config: Config = process.env.DOPPLER_ENV
  ? JSON.parse(process.env.DOPPLER_ENV)
  : process.env;

// Shorthand environment checks.
//
// e.g.
// if (isDev) {
//   logger.info('Currently in development mode');
// }
export const isDev = config.RESPELL_ENV === 'development';
export const isStaging = config.RESPELL_ENV === 'staging';
export const isProd = config.RESPELL_ENV === 'production';
