import { TriggerDefinition } from '../../../definition';

export default {
  key: 'intercom-conversations',
  name: 'New Conversation',
  description: 'A conversation is created in Intercom.',
  icon: 'i-logos-intercom-icon',
  options: {},
  outputs: {
    conversation: {
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'Conversation ID',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'fingerprint',
            },
            value: null,
          },
          created_at: {
            key: 'created_at',
            name: 'Created At',
            type: 'date',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'calendar',
            },
            value: null,
          },
          updated_at: {
            key: 'updated_at',
            name: 'Updated At',
            type: 'date',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'calendar',
            },
            value: null,
          },
          title: {
            key: 'title',
            name: 'Title',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'title',
            },
            value: null,
          },
          state: {
            key: 'state',
            name: 'State',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'state',
            },
            value: null,
          },
          content: {
            key: 'content',
            name: 'Content',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'content',
            },
            value: null,
          },
          open: {
            key: 'open',
            name: 'Open',
            type: 'boolean',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'open',
            },
            value: null,
          },
          read: {
            key: 'read',
            name: 'Read',
            type: 'boolean',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'read',
            },
            value: null,
          },
          priority: {
            key: 'priority',
            name: 'Priority',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'priority',
            },
            value: null,
          },
        }
      },
      name: 'conversation',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'conversation'
    }
  }
} satisfies TriggerDefinition;
