import { StepDefinition } from '../../types';

export default {
  key: 'add_applicant_greenhouse',
  name: 'Add Applicant',
  description: 'Consider a candidate for a job.',
  icon: 'i-respell-greenhouse',
  category: 'integrations',
  services: ['greenhouse'],
  options: {
    candidateId: {
      key: 'candidateId',
      name: 'Canididate',
      description: 'The the candidate to added.',
      isOptional: false,
      type: 'number/integer',
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_canididates',
          },
        },
      },
      listDepth: 0,
      value: null,
    },
    jobId: {
      key: 'jobId',
      name: 'Job',
      description: 'The job to add the candidate to.',
      isOptional: true,
      metadata: {
        options: {
          reference: {
            namespace: 'greenhouse',
            member: 'list_jobs',
          },
        },
      },
      type: 'number/integer',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
