<script setup lang="ts">
import type { Json } from '@respell/utils';
import { useChangeCase } from '@vueuse/integrations/useChangeCase.mjs';
import VariableNode from '~/components/app/VariableNode.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';

const props = defineProps<{
  options: any[] | Json;
  searchable?: boolean;
  creatable?: boolean;
  isSearching?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchablePlaceholder?: string;
  injectable?: boolean;
}>();

const modelValue = defineModel<string | string[]>();
const searchQuery = defineModel<string>('query', {
  required: false,
  default: '',
});

const { emitFormChange } = useFormGroup();

const dropdownOptions = ref(Array.isArray(props.options) ? props.options : []);

// Update dropdownOptions when options hydrate after search
watchDeep(props, (newVal, oldVal) => {
  if (
    (oldVal.isSearching && !newVal.isSearching) ||
    newVal.options !== !oldVal.options
  ) {
    dropdownOptions.value = props.options;
  }
});

const valueAttribute = computed(() => {
  return dropdownOptions.value?.[0]?.id
    ? 'id'
    : dropdownOptions.value[0]?.key
      ? 'key'
      : 'name';
});

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return !!matches;
  }
  return false;
});

onBeforeMount(() => {
  // If the value is a variable, do nothing
  if (isVariable.value) {
    return;
  }

  // Handle multiple selection
  if (props.multiple && modelValue.value?.length) {
    const customOptions = useArrayDifference(
      modelValue.value,
      dropdownOptions.value.map((option) => option[valueAttribute.value]),
    ).value;

    // Add custom options to dropdownOptions
    if (customOptions.length) {
      dropdownOptions.value = [
        ...dropdownOptions.value,
        ...customOptions.map(createNewOption),
      ];
    }
  } else {
    // Handle single selection
    if (
      modelValue.value &&
      !dropdownOptions.value
        .map((option) => option[valueAttribute.value])
        .includes(modelValue.value)
    ) {
      if (props.creatable) {
        // Add new option if creatable
        dropdownOptions.value.push(createNewOption(modelValue.value));
      } else {
        // Set modelValue to null if not creatable
        modelValue.value = null;
      }
    }
  }
});

const selected = computed({
  get: () => (!modelValue.value && props.multiple ? [] : modelValue.value),
  set: (selection) => {
    const { multiple, creatable } = props;
    const { value: options } = dropdownOptions;

    if (multiple) {
      modelValue.value = modelValue.value ?? [];

      const newEntry = modelValue.value?.length
        ? useArrayDifference(selection, modelValue.value).value?.[0]
        : selection?.[0];

      if (newEntry) {
        // Check if the new entry already exists in dropdown options
        if (
          !options?.some((option) => option[valueAttribute.value] === newEntry)
        ) {
          const newOption = createNewOption(newEntry.name);
          dropdownOptions.value.push(newOption);
          modelValue.value = [
            ...modelValue.value,
            newOption[valueAttribute.value],
          ];
          return;
        }
      }
      modelValue.value = selection;
    } else {
      // Handle single selection
      if (modelValue.value === selection) {
        modelValue.value = null;
        return;
      }

      if (
        options?.some((option) => option[valueAttribute.value] === selection)
      ) {
        modelValue.value = selection;
      } else if (creatable) {
        // Create and add new option if creatable
        const newOption = createNewOption(selection.name ?? selection);
        dropdownOptions.value.push(newOption);
        modelValue.value = newOption[valueAttribute.value];
      }
    }
  },
});

function createNewOption(name: string) {
  return {
    key: useChangeCase(name, 'camelCase').value,
    id: useChangeCase(name, 'camelCase').value,
    name: useChangeCase(name, 'capitalCase').value,
  };
}
</script>
<template>
  <div class="flex flex-row items-center gap-3 justify-between">
    <USelectMenu
      v-model="selected"
      v-model:query="searchQuery"
      :value-attribute="valueAttribute"
      option-attribute="name"
      :search-attributes="['name', valueAttribute]"
      :options="dropdownOptions"
      :searchable="searchable || creatable"
      :disabled="disabled || isVariable"
      :loading="isSearching"
      :creatable="creatable"
      :multiple="multiple"
      :searchable-placeholder="
        searchablePlaceholder ?? creatable
          ? 'Search for options or create one...'
          : 'Search for results...'
      "
      show-create-option-when="always"
      selected-icon="i-ph-x-circle-fill"
      class="grow w-full"
      @update:model-value="emitFormChange"
    >
      <template #label>
        <span class="flex gap-2 max-w-full overflow-scroll justify-start">
          <span v-if="!multiple && selected" class="contents">
            <UIcon
              v-if="
                dropdownOptions.find((o) => o[valueAttribute] === selected)
                  ?.icon
              "
              :name="
                dropdownOptions.find((o) => o[valueAttribute] === selected)
                  ?.icon
              "
              class="w-4 h-4"
            />
            <p class="truncate">
              {{
                dropdownOptions.find((o) => o[valueAttribute] === selected)
                  ?.name
              }}
            </p>
          </span>
          <span
            v-for="(option, index) in selected"
            v-else-if="selected?.length"
            :key="option"
            class="flex gap-2"
          >
            <UIcon
              v-if="
                dropdownOptions.find((o) => o[valueAttribute] === option)?.icon
              "
              :name="
                dropdownOptions.find((o) => o[valueAttribute] === option)?.icon
              "
              class="w-4 h-4"
            />
            <p class="truncate">
              {{
                dropdownOptions.find((o) => o[valueAttribute] === option)?.name
              }}
              {{ index + 1 < selected.length ? ',' : '' }}
            </p>
          </span>
          <span v-else class="dimmed">{{
            placeholder
              ? placeholder
              : multiple
                ? 'Select one or more options'
                : 'Select an option'
          }}</span>
        </span>
      </template>
      <template #option-create="{ option: newOption }">
        <span class="text-gray-500">Create:</span> "{{
          newOption.name ?? newOption
        }}"
      </template>
      <template #empty> No options available</template>
    </USelectMenu>
    <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
    <VariablePicker
      v-else-if="injectable"
      v-model="modelValue"
      is-range
      type="text/plain"
      @update:model-value="emitFormChange"
    >
      <template #button="{ incoming }">
        <UButton
          color="white"
          :disabled="!incoming.valid?.length"
          size="2xs"
          :ui="{
            font: 'font-normal',
            inline: 'w-fit',
          }"
        >
          <span
            class="flex flex-row items-center gap-2 text-sm"
            :class="!incoming.valid?.length ? 'text-gray-300' : 'text-gray-500'"
          >
            {{ 'Or select variable' }}
            <UIcon name="i-ph-caret-down" class="w-4 h-4" />
          </span>
        </UButton>
      </template>
    </VariablePicker>
  </div>
</template>
