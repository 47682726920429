import { StepDefinition } from '../../types';

export default {
  key: 'text_to_document',
  name: 'Text to Document',
  description: 'Creates a file containing the specified text.',
  icon: 'i-respell-text-to-doc',
  category: 'file',
  documentationLink:
    'https://docs.respell.ai/steps-reference/file-tools/text-to-document',
  options: {
    content: {
      key: 'content',
      name: 'Content',
      description: 'The text content you want to place in the file.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        isMultiline: true,
      },
      value: null,
    },
    filename: {
      key: 'filename',
      name: 'Filename',
      description: 'The name of the file to create, the file is always a .txt file.',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
    // file_type: {
    //   key: 'file_type',
    //   name: 'File Type',
    //   description: 'The file type to create.',
    //   isOptional: false,
    //   type: 'text/plain',
    //   listDepth: 0,
    //   metadata: {
    //     options: [
    //       {
    //         key: 'pdf',
    //         name: 'PDF (.pdf)',
    //         icon: 'i-respell-pdf',
    //       },
    //       {
    //         key: 'text',
    //         name: 'Text (.txt)',
    //         icon: 'i-respell-text',
    //       },
    //       {
    //         key: 'json',
    //         name: 'JSON (.json)',
    //         icon: 'i-respell-json',
    //       },
    //       {
    //         key: 'md',
    //         name: 'Markdown (.md)',
    //         icon: 'i-respell-md',
    //       },
    //     ],
    //   },
    //   value: 'text',
    // }
  },
  outputs: {
    file: {
      key: 'file',
      name: 'File',
      description: 'The newly created file.',
      type: 'file/document',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
