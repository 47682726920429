<script setup lang="ts">
import type { GraphRun } from '@respell/database';
import AppReviewItem from '~/components/app/AppReviewItem.vue';
import { useStreaming } from '~/composables/useStreaming';

const { runId } = defineProps<{
  runId: string;
}>();

const modal = useModal();
const workspaceStore = useWorkspaceStore();

const { streamSpell } = useStreaming();

const { data: reviewItem } = useAsyncData<GraphRun>(`review/${runId}`, () =>
  workspaceStore.fetchReviewItem(runId as string),
);

const handleResume = async () => {
  const inputs = Object.fromEntries(
    Object.entries(reviewItem.value?.graph.inputs)
      .filter(([_, input]) => input.metadata.forReview === true)
      .map(([key, _]) => [key, reviewItem.value?.outputs[key]]),
  );

  workspaceStore.approveReview(runId, inputs);
  streamSpell({ spellId: reviewItem.value?.spellId, runId });
  modal.close();
};

const handleReject = async () => {
  workspaceStore.rejectReview(runId);
  streamSpell({ spellId: reviewItem.value?.spellId, runId });
  modal.close();
};
</script>
<template>
  <UModal>
    <AppReviewItem
      v-if="reviewItem"
      :item="reviewItem"
      type="todo"
      :handle-reject="handleReject"
      :handle-resume="handleResume"
      :close-modal="modal.close"
    />
  </UModal>
</template>
