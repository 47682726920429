<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
  <div
    class="flex flex-col justify-center h-screen gap-5 bg-primary-200 w-screen"
  >
    <h1 class="font-bold text-8xl text-red-500">
      {{ error.statusCode }}
    </h1>
    <h2 class="main-title">{{ error.message }}</h2>
    <p class="title dimmed">Oops! Something went wrong. Please try again.</p>
    <p class="subtitle dimmed">
      Old link not working? We migrated our app to V2, you can still access old
      spells at <a href="https://v1.respell.ai">v1.respell.ai</a>
    </p>
    <UButton
      class="mt-l"
      size="xl"
      @click="handleError"
      :ui="{ rounded: 'rounded-lg' }"
    >
      Return Home
    </UButton>
  </div>
</template>
