import { Integration } from '../definition.js';

export default {
  key: 'coda',
  name: 'Instantly',
  icon: 'i-respell-instantly',
  options: [
    {
      key: 'apiKey',
      name: 'API Key',
      description: 'Instanty API Key',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  ],
  postAuthOptions: [],
} satisfies Integration;
