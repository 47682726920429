import { isProd } from '@respell/utils/config';

export default {
  // Identify a user for subsequent tracking.
  identify(email: string) {
    if (isProd && import.meta.client) {
      const _hsq = (window._hsq = window._hsq || []);

      _hsq.push([
        'identify',
        {
          email,
          // You can add other user attributes here
        },
      ]);
    }
  },
  trackUtm(email: string, utmData: object) {
    if (isProd && import.meta.client) {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email,
          ...utmData,
        },
      ]);
    }
  },
  track(email: string, utmData: object) {
    if (isProd && import.meta.client) {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email,
          ...utmData,
        },
      ]);
    }
  },
  page() { },
};
