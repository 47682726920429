import type { User } from '@respell/database';
import { defineStore } from 'pinia';
import { defineRulesForUser } from '~/util/defineAbility';

export const useCaslStore = defineStore('casl', () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const ability = useAppAbility();

  // state example, left for reference
  // const count = ref(0);

  // getters example, left for reference
  // const doubleCount = computed(() => count.value * 2);

  // actions example, left for reference
  // const increment = () => {
  //   count.value++;
  // };

  const updateRules = () => {
    const rules = defineRulesForUser(user.value as User);
    ability.update(rules);
  };

  return {
    // return state, getters and actions
    // count,
    // doubleCount,
    // increment,
    updateRules,
  };
});
