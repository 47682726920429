import { StepDefinition } from '../../types';

export default {
  key: 'create_opportunity_highlevel',
  name: 'Create Opportunity',
  description: 'Creates a GoHighLevel Opportunity',
  icon: 'i-respell-highlevel',
  category: 'integrations',
  services: ['highlevel'],
  options: {
    location_id: {
      name: 'Location ID',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'search_locations',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
            ],
          },
        },
      },
      key: 'location_id',
    },
    assignedTo: {
      name: 'Assigned To',
      type: 'text/plain',
      listDepth: 0,
      isOptional: true,
      value: null,
      conditions: [
        {
          reference: 'location_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        isSearchable: true,
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'search_users',
            arguments: [
              {
                namespace: 'options',
                member: 'query',
                parameter: 'query',
              },
              {
                namespace: 'options',
                member: 'location_id',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      key: 'assignedTo',
    },
    name: {
      name: 'Name',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'name',
    },
    status: {
      name: 'Status',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'status',
      metadata: {
        options: [
          {
            id: 'open',
            name: 'Open',
          },
          {
            id: 'won',
            name: 'Won',
          },
          {
            id: 'lost',
            name: 'Lost',
          },
          {
            id: 'abandoned',
            name: 'Abandoned',
          },
        ],
        // values: ['open', 'won', 'lost', 'abandoned']
      },
    },
    contact_id: {
      name: 'Assigned Contact',
      type: 'text/plain',
      listDepth: 0,
      isOptional: false,
      conditions: [
        {
          reference: 'location_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'list_contacts',
            arguments: [
              {
                namespace: 'options',
                member: 'location_id',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      value: null,
      key: 'contact_id',
    },
    monetaryValue: {
      name: 'Value',
      type: 'number/integer',
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'monetaryValue',
    },
    pipeline_id: {
      name: 'Pipline',
      type: 'text/plain',
      listDepth: 0,
      conditions: [
        {
          reference: 'location_id',
          type: 'not',
          value: null,
        },
      ],
      isOptional: false,
      metadata: {
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'list_pipelines',
            arguments: [
              {
                namespace: 'options',
                member: 'location_id',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      value: null,
      key: 'pipeline_id',
    },
    pipeline_stage_id: {
      name: 'Stage',
      type: 'text/plain',
      conditions: [
        {
          reference: 'pipeline_id',
          type: 'not',
          value: null,
        },
      ],
      metadata: {
        options: {
          reference: {
            namespace: 'highlevel',
            member: 'list_stages_hl',
            arguments: [
              {
                namespace: 'options',
                member: 'pipeline_id',
                parameter: 'pipeline_id',
              },
              {
                namespace: 'options',
                member: 'location_id',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
      listDepth: 0,
      isOptional: true,
      value: null,
      key: 'pipeline_stage_id',
    },
    customFields: {
      name: 'Custom Fields',
      type: 'object',
      listDepth: 0,
      isOptional: true,
      value: {},
      conditions: [
        {
          reference: 'location_id',
          type: 'not',
          value: null,
        },
      ],
      key: 'customFields',
      metadata: {
        schema: {
          reference: {
            namespace: 'highlevel',
            member: 'get_custom_fields_opportunity',
            arguments: [
              {
                namespace: 'options',
                member: 'location_id',
                parameter: 'location_id',
              },
            ],
          },
        },
      },
    },

  },
  outputs: {},
} satisfies StepDefinition;
