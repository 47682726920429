import { StepDefinition } from '../../types';

export default {
  key: 'create_apollo_contact',
  name: 'Create Contact',
  description: 'Creates a contact in Apollo.',
  icon: 'i-respell-apollo',
  category: 'integrations',
  services: ['apollo'],
  options: {
    first_name: {
      key: 'first_name',
      name: 'First Name',
      description: 'First name of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      value: null,
    },
    last_name: {
      key: 'last_name',
      name: 'Last Name',
      description: 'Last name of the person.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      value: null,
    },
    email: {
      name: 'Email',
      type: 'text/email',
      description: 'Email of the person.',
      listDepth: 0,
      isOptional: false,
      value: null,
      key: 'email',
    },


    organization_name: {
      key: 'organization_name',
      name: 'Organiztion Name',
      description: 'Current organization/company of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-building-apartment-bold',
      },
      value: null,
    },
    title: {
      key: 'title',
      name: 'Title',
      description: 'The title of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      value: null,
    },
    direct_phone: {
      key: 'direct_phone',
      name: 'Direct Phone',
      description: 'Direct phone number of the person.',
      type: 'text/phone-number',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-phone',
      },
      value: null,
    },
    present_raw_address: {
      key: 'present_raw_address',
      name: 'Present Raw Address',
      description: 'Present raw address of the person.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-location',
      },
      value: null,
    },
    website_url: {
      key: 'website_url',
      name: 'Website URL',
      description: 'Website URL of the person.',
      type: 'text/url',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-globe',
      },
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
