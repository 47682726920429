import type { Profile, User } from '@prisma/client';
import { posthog } from 'posthog-js';
import { useWorkspaceStore } from '~/stores/workspaces';

export default {
  // Identify a user for subsequent tracking.
  identify(user: User, profile: Profile) {
    const workspaceStore = useWorkspaceStore();
    const { subscriptionType } = storeToRefs(workspaceStore);

    // Update the user details.
    if (user && user.email && profile.fullName) {
      posthog.identify(user.email, {
        email: user.email,
        name: profile.fullName,
        currentSubscription: subscriptionType.value,
      });
    }
  },
  track() {},
  page() {},
};
